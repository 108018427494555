/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from "axios";
import {
  IAccount,
  IAccounts,
  IAccountLog,
  IAccountLogs,
  IAccountFormData,
  IProxyIpsMappedData,
  IProxyIpsResponse,
  IInactivePeriod,
  IInactivePeriods,
  IIsOnline,
  IPreviewTemplateInput,
  IPreviewTemplateOutput,
  IAccountPlaceholders,
  IAccountPasswordChangeFormValues,
  IAccountApiKeys,
  ICreateAccountApiKeyFormValues,
  IUpdateAccountApiKeyFormValues,
  IAccountApiKey,
  IAccountMetaData,
  ICreateAccountResponse,
} from "../models";

const fetchAccounts = (
  page: number,
  pageSize: number
): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(`/inevitable/accounts/?page=${page}&page_size=${pageSize}`);
};

const fetchAllAccounts = (): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(`/inevitable/accounts/?page=1&page_size=1000`);
};

const fetchAccountsWithSearch = (
  search = "",
  page: number
): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(
    `/inevitable/accounts/?search=${search}&page=${page}&page_size=100`
  );
};

const fetchAllIncompleteAccounts = (): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(
    `/inevitable/accounts/?page=1&page_size=1000&person_id__isnull=true`
  );
};

const fetchMostRecentIncompleteAccounts = (): Promise<
  AxiosResponse<IAccounts>
> => {
  return axios.get(
    `/inevitable/accounts/?page=1&page_size=1&person_id__isnull=true`
  );
};

const fetchExistingSyncedAccounts = (): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(
    `/inevitable/accounts/?page=1&page_size=1&person_id__isnull=false`
  );
};

const fetchExistingAccounts = (): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(`/inevitable/accounts/?page=1&page_size=1`);
};

const fetchAccount = (id: number): Promise<AxiosResponse<IAccount>> => {
  return axios.get(`/inevitable/accounts/${id}/`);
};

const createAccount = (
  data: IAccountFormData
): Promise<AxiosResponse<ICreateAccountResponse>> => {
  return axios.post("/inevitable/accounts/", data);
};

const updateAccount = (
  id: number,
  data: any
): Promise<AxiosResponse<IAccount>> => {
  return axios.patch(`/inevitable/accounts/${id}/`, data);
};

const updateAccountMetaData = (
  id: number,
  data: IAccountMetaData
): Promise<AxiosResponse<IAccount>> => {
  return axios.patch(`/inevitable/accounts/${id}/`, data);
};

const updateAccountPassword = (
  id: number,
  data: IAccountPasswordChangeFormValues
): Promise<AxiosResponse<IAccountPasswordChangeFormValues>> => {
  return axios.patch(`/inevitable/accounts/${id}/change_password/`, data);
};

const updateAccountPlaceholders = (
  id: number,
  data: IAccountPlaceholders
): Promise<AxiosResponse<IAccount>> => {
  return axios.patch(`/inevitable/accounts/${id}/`, { placeholders: data });
};

const fetchProxy = (
  id: number | undefined
): Promise<AxiosResponse<IProxyIpsResponse>> => {
  return axios.get(`/inevitable/proxies/${id}/`);
};

const createProxyIp = (
  data: IProxyIpsMappedData
): Promise<AxiosResponse<IProxyIpsResponse>> => {
  return axios.post("/inevitable/proxies/", data);
};

const fetchInactivePeriods = (
  id: number
): Promise<AxiosResponse<IInactivePeriods>> => {
  return axios.get(`/inevitable/inactive_periods/?account_id=${id}`);
};

const createInactivePeriod = (
  id: number,
  data: IInactivePeriod
): Promise<AxiosResponse<any>> => {
  return axios.post(`/inevitable/inactive_periods/`, { account: id, ...data });
};

const deleteInactivePeriod = (
  id: number
): Promise<AxiosResponse<IInactivePeriod>> => {
  return axios.delete(`/inevitable/inactive_periods/${id}/`);
};

const fetchAccountLogs = (
  id: number,
  index: number
): Promise<AxiosResponse<IAccountLogs>> => {
  return axios.get(
    `/inevitable/account_logs/?account_id=${id}&status=ls&status=lf&status=ar&status=uf&status=af&status=il&index=${index}`
  );
};

const fetchLastAccountLog = (
  id: number
): Promise<AxiosResponse<IAccountLogs>> => {
  return axios.get(
    `/inevitable/account_logs/?page_size=1&page=1&account_id=${id}&status=ls&status=ar&status=uf&status=af&status=lo&status=il&status=lf`
  );
};

const updateAccountLog = (
  id: number,
  data: IAccountLog
): Promise<AxiosResponse<IAccountLog>> => {
  return axios.patch(`/inevitable/account_logs/${id}/`, data);
};

const fetchAccountIsOnline = (
  accountId: number
): Promise<AxiosResponse<IIsOnline>> => {
  return axios.get(`/inevitable/accounts/${accountId}/is_online/`);
};

const fetchPreviewTemplate = (
  accountId: number,
  data: IPreviewTemplateInput
): Promise<AxiosResponse<IPreviewTemplateOutput>> => {
  return axios.post(`/inevitable/accounts/${accountId}/render_template/`, data);
};

const fetchApiKeys = (
  accountId: number
): Promise<AxiosResponse<IAccountApiKeys>> => {
  return axios.get(
    `/inevitable/account_api_keys/?revoked=false&account_id=${accountId}`
  );
};

const createApiKey = (
  accountId: number,
  data: ICreateAccountApiKeyFormValues
): Promise<AxiosResponse<IAccountApiKey>> => {
  return axios.post("/inevitable/account_api_keys/", {
    ...data,
    account: accountId,
  });
};

const updateApiKey = (
  apiId: string,
  data: IUpdateAccountApiKeyFormValues
): Promise<AxiosResponse<IAccountApiKeys>> => {
  return axios.patch(`/inevitable/account_api_keys/${apiId}`, data);
};

const AccountService = {
  fetchAllAccounts,
  fetchAccountsWithSearch,
  fetchAllIncompleteAccounts,
  fetchMostRecentIncompleteAccounts,
  fetchExistingAccounts,
  fetchExistingSyncedAccounts,
  fetchAccounts,
  fetchAccount,
  createAccount,
  updateAccount,
  updateAccountMetaData,
  updateAccountPassword,
  updateAccountPlaceholders,
  fetchProxy,
  createProxyIp,
  fetchInactivePeriods,
  createInactivePeriod,
  deleteInactivePeriod,
  fetchLastAccountLog,
  fetchAccountLogs,
  updateAccountLog,
  fetchAccountIsOnline,
  fetchPreviewTemplate,
  fetchApiKeys,
  createApiKey,
  updateApiKey,
};

export default AccountService;
