import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import InfoIcon from "ui-kit/icons/InfoIcon";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    margin: theme.spacing(0, 2.5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0, 1),
    },
  },
  chip: {
    cursor: "pointer",
    backgroundColor: "transparent",
    color: theme.app.palette.action.color,
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1, 0, 0),
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    cursor: "pointer",
    color: theme.app.palette.action.color,
    "& > div$icon": {
      color: theme.app.palette.action.color,
    },
    "&:hover": {
      color: theme.app.palette.text.secondary,
      "& > div$icon": {
        color: theme.app.palette.text.secondary,
      },
    },
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
}));

export interface HelperInfoProps {
  link: string;
}

const HelperInfo = ({ link }: HelperInfoProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className={classes.root}>
          <div className={classes.icon}>
            <InfoIcon type="docs" />
          </div>
          How to use
        </div>
      </a>
    </div>
  );
};

export default HelperInfo;
