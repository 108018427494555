import AddIcon from "@mui/icons-material/Add";
import Timeline from "@mui/lab/Timeline";
import { Alert } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import { CampaignQuery } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { IGroupedInteractions } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import {
  getGroupedInteractions,
  getInteractions,
} from "modules/Interaction/utils";
import RecentActivityItem from "modules/Report/components/RecentActivityItem";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "react-query";
import { Link } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import Loader from "ui-kit/components/Loader";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(3.75),
  },
  action: {
    marginTop: theme.spacing(-1.25),
    marginBottom: theme.spacing(-1.25),
  },
  list: {
    margin: 0,
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 0),
    overflowY: "scroll",
    height: theme.app.constants.report.box,
    ...theme.app.constants.scrollbar,
  },
  listCampaign: {
    maxHeight: theme.app.constants.report.box,
    height: "auto",
  },
  listPerson: {
    padding: theme.spacing(4, 0),
    height: "100%",
  },
  listEmpty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    height: theme.app.constants.report.box,
  },
  loadMoreScroll: {
    height: 60,
  },
  groupDate: {
    color: theme.app.palette.action.placeholder,
    fontSize: 12,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(18),
  },
}));

interface RecentActivityListProps {
  campaignId?: string;
  personId?: string;
}

const RecentActivityList = ({
  campaignId = "",
  personId = "",
}: RecentActivityListProps): React.ReactElement => {
  const classes = useStyles();
  const [interactions, setInteractions] = React.useState<IGroupedInteractions>(
    {}
  );

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  // Verify if campaigns exist
  const { data: campaignsExist } = useQuery(
    [CampaignQuery.campaigns_exist, accountId],
    async () => {
      const result = await CampaignService.fetchExistingRunningCampaigns();

      return result?.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchInteractions = async ({
    pageParam = ConfigConstant.INITIAL_PAGE,
  }) => {
    try {
      const { data: d } = await InteractionService.fetchAccountInteractions(
        accountId,
        pageParam,
        personId,
        campaignId
      );
      return d;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery(
      ["recent-activity", accountId, campaignId, personId],
      fetchInteractions,
      {
        getNextPageParam: (lastPage) =>
          lastPage.next ? lastPage.current + 1 : false,
        keepPreviousData: true,
        enabled: !!accountId,
      }
    );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const loadMoreDisabled = Boolean(!hasNextPage || isFetchingNextPage);
  React.useEffect(() => {
    if (inView && !loadMoreDisabled) {
      fetchNextPage();
    }
  }, [inView, loadMoreDisabled, fetchNextPage]);

  React.useEffect(() => {
    if (data) {
      const allInteractions = getInteractions(data);
      const groupedInteractions = getGroupedInteractions(allInteractions);
      setInteractions(groupedInteractions);
    }
  }, [data]);

  const isEmptyResults = data && Object.keys(interactions).length === 0;

  return (
    <Timeline
      className={clsx({
        [classes.list]: true,
        [classes.listPerson]: !!personId,
        [classes.listCampaign]: !!campaignId,
        [classes.listEmpty]: !!isEmptyResults,
      })}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {!!isEmptyResults &&
        (campaignsExist?.count ? (
          <Typography sx={{ padding: [2, 6] }} variant="body2">
            Once your first campaign starts, activity will appear here...
          </Typography>
        ) : (
          <>
            <Alert severity="warning" sx={{ mb: 3 }}>
              Launch campaign to start seeing your activity.
            </Alert>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              component={Link}
              to={RouterConstants.CAMPAIGN.NEW_START}
              startIcon={<AddIcon />}
            >
              Create campaign
            </Button>
          </>
        ))}
      {Object.keys(interactions).map((key, index1) => {
        const items = interactions[key];
        const isLastGroup = Object.keys(interactions).length - 1 === index1;
        return (
          <React.Fragment key={key}>
            <Typography className={classes.groupDate}>{key}</Typography>
            {items.map((interaction, index2) => {
              const isLast = isLastGroup && items.length - 1 === index2;
              return (
                <RecentActivityItem
                  interaction={interaction}
                  key={interaction.id}
                  isLast={isLast}
                />
              );
            })}
          </React.Fragment>
        );
      })}
      <>
        {isLoading ||
          (hasNextPage && (
            <div className={classes.loadMoreScroll}>
              <Loader />
            </div>
          ))}
        {/* Load more element */}
        <div ref={ref} />
      </>
    </Timeline>
  );
};

RecentActivityList.defaultProps = {
  campaignId: "",
  personId: "",
};

export default RecentActivityList;
