/* eslint-disable consistent-return */
import { yupResolver } from "@hookform/resolvers/yup";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import ElementField from "core/components/ElementField";
import ConfigConstant from "core/constants/ConfigConstant";
import { CountryCodes, CountryIds, Platforms } from "core/models";
import RouterConstants from "core/routes/constants";
import { matchCountry } from "core/utils/countryHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { stripePromise } from "core/utils/paymentHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  IAccount,
  IAccountCurrent,
  IAccountFormInput,
  IAccountFormValues,
} from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import { getDefaultTimezone } from "modules/Account/utils";
import AccountValidation from "modules/Account/validations";
import CompanyAutocomplete from "modules/Company/components/CompanyAutocomplete";
import {
  Currencies,
  ICustomer,
  RecurringInterval,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import { UserContext } from "modules/User/context";
import UserService from "modules/User/services";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import HelperTooltip from "ui-kit/components/HelperTooltip";
import PasswordField from "ui-kit/components/PasswordField";
import CountryAutocomplete from "./components/CountryAutocomplete";
import PageHeader from "ui-kit/components/PageHeader";

type ParamTypes = {
  accountId: string;
};

const createDefaultValues = (
  account: IAccount | undefined,
  country: CountryIds | undefined
) => ({
  login: get(account, "login") || "",
  password: get(account, "password") || "",
  company: get(account, "company")
    ? {
        id: get(account, "company.id"),
        name: get(account, "company.name"),
      }
    : null,
  country: get(account, "country")
    ? matchCountry(get(account, "country"))
    : !!country
    ? matchCountry(country)
    : null,
});

const CreateAccount = (): React.ReactElement => {
  const params = useParams<ParamTypes>();
  const accountId = +params.accountId || 0;
  const queryClient = useQueryClient();

  const {
    account: { id: currentAccountId },
    dispatch,
  } = React.useContext(AccountContext);

  const {
    user: { frontend_state },
  } = React.useContext(UserContext);
  const customPrice: string = get(frontend_state, "prices.0");

  const [newAccountId, setNewAccountId] = React.useState<number | undefined>(
    undefined
  );

  const [localeCurrency, setLocaleCurrency] = React.useState<Currencies>(
    Currencies.usd
  );
  const [customerCurrency, setCustomerCurrency] = React.useState<Currencies>();
  const [freePriceId, setFreePriceId] = React.useState<string>("");
  const [paidPriceId, setPaidPriceId] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const { data: account } = useQuery(
    [AccountQuery.new, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!accountId,
    }
  );

  const { data: country } = useQuery(
    ["user-location"],
    async () => {
      try {
        const response = await UserService.fetchUserLocation();
        return response.data?.ip_metadata?.country_code?.toLowerCase() as CountryIds;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // If navigated to the URL and current account isn't selected
  React.useEffect(() => {
    if (!!account && !!accountId && accountId !== currentAccountId) {
      dispatch({
        type: AccountActionType.SET_ACCOUNT,
        payload: account,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, currentAccountId, account]);

  const [defaultValues, setDefaultValues] = React.useState(
    createDefaultValues(account, country)
  );

  const history = useHistory();
  const theme = useTheme();

  const schema = AccountValidation.accountFormSchema(!!accountId);
  const { errors, control, handleSubmit, reset, setError, setValue } =
    useForm<IAccountFormInput>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  // Update default values after fetching account
  React.useEffect(() => {
    if (account) {
      const result = createDefaultValues(account, country);
      setDefaultValues(result);
      reset(result);
    }
  }, [reset, account, country]);

  // Reset default values, if already on the component and tries to create new account
  React.useEffect(() => {
    if (accountId !== account?.id) {
      const newDefaultValues = createDefaultValues(account, country);
      setDefaultValues(newDefaultValues);
      reset(newDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  // Only on the first run
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (!firstUpdate.current) {
      return;
    }

    if (country) {
      // Update firstUpdate to not run again
      firstUpdate.current = false;

      // Update selected country
      setValue("country", matchCountry(country));

      // Update preferred currency
      const localeCurrency = PaymentUtils.getLocaleCurrency(country);
      setLocaleCurrency(localeCurrency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const { data: isExistingAccount } = useQuery(
    [AccountQuery.exists, accountId],
    async () => {
      try {
        const response = await AccountService.fetchExistingAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchCustomers = async () => {
    try {
      const { data } = await PaymentService.fetchCustomers();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCustomers } = useQuery(
    ["customers"],
    () => fetchCustomers(),
    {
      keepPreviousData: true,
    }
  );

  const existingCustomer: ICustomer | undefined = get(
    dataCustomers,
    "results[0]"
  );

  React.useEffect(() => {
    if (existingCustomer?.currency) {
      setCustomerCurrency(existingCustomer.currency);
    }
  }, [existingCustomer]);

  // Set default price
  const fetchPrices = async () => {
    try {
      const { data } = await PaymentService.fetchPrices();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPrices } = useQuery(
    ["prices", "all"],
    () => fetchPrices(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (!!dataPrices?.count) {
      const newFreePriceId = dataPrices?.results.find(
        (p) =>
          p.data.nickname === ConfigConstant.PRICE.TRIAL.NICKNAME &&
          (p.data.currency === customerCurrency ||
            p.data.currency === localeCurrency) &&
          p.data.recurring.interval === RecurringInterval.month
      );

      // Set free priceID
      if (!!newFreePriceId) {
        setFreePriceId(newFreePriceId.id);
      }

      const newPaidPriceId = dataPrices?.results.find(
        (p) =>
          p.data.nickname === ConfigConstant.PRICE.FULL.NICKNAME &&
          (p.data.currency === customerCurrency ||
            p.data.currency === localeCurrency) &&
          p.data.recurring.interval === RecurringInterval.month
      );

      // Set paid priceID
      if (!!newPaidPriceId) {
        setPaidPriceId(newPaidPriceId.id);
      }

      if (!!customPrice) {
        setPaidPriceId(customPrice);
      }
    }
  }, [dataPrices, customerCurrency, localeCurrency, customPrice]);

  const mutateCheckout = useMutation(
    ({ accountId, priceId }: { accountId: number; priceId: string }) =>
      PaymentService.createSubscriptionSession({
        price: priceId,
        account: accountId,
        ...PaymentUtils.getCheckoutUrls(accountId),
      }),
    {
      onSuccess: async (response) => {
        const stripe = await stripePromise;
        stripe?.redirectToCheckout({
          sessionId: response.data.id,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleResponse = (payload: IAccountCurrent) => {
    dispatch({
      type: AccountActionType.SET_ACCOUNT,
      payload, // Store values to enable moving between steps
    });

    // Invalidate query
    queryClient.invalidateQueries([
      AccountQuery.account_subscription,
      payload.id,
    ]);

    queryClient.invalidateQueries([AccountQuery.all]);
  };

  React.useEffect(() => {
    if (newAccountId === currentAccountId) {
      // Go to main page for sync
      history.push(RouterConstants.ROOT);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAccountId, currentAccountId]);

  const mutateAccount = useMutation(
    (credentials: IAccountFormValues) => {
      const newCredentials = {
        login: credentials.login,
        password: credentials.password,
        time_zone: getDefaultTimezone(),
        platform: Platforms.linkedin,
        company: get(credentials, "company.id") || null,
        country: credentials.country.id as CountryCodes,
        price: freePriceId,
        // meta_data: {
        //   onboarding: {
        //     ...AccountConstants.EMPTY_CURRENT_ACCOUNT.meta_data?.onboarding,
        //   },
        // },
      };

      // Disable submit button
      setIsSubmitting(true);

      // New account currently in the newAccount context
      if (accountId && defaultValues.login === account?.login) {
        return AccountService.updateAccount(accountId, newCredentials);
      }

      // Non-existing account -> reset storage, create it
      return AccountService.createAccount(newCredentials);
    },
    {
      onSuccess: (response, credentials) => {
        const newData = {
          ...credentials, // Need credentials as we back-read default data as option item
          ...response.data,
        };

        // Handle new trial
        handleResponse(newData);

        if (get(response, "data.card_less_subscription_created")) {
          // Created a new trial account
          // Redirect new account
          setNewAccountId(newData.id);

          // Enable submit button
          setIsSubmitting(false);
        } else {
          // Continue to checkout
          if (!!paidPriceId) {
            mutateCheckout.mutate({
              accountId: newData.id,
              priceId: paidPriceId,
            });
          }
        }
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);

        // Enable submit button
        setIsSubmitting(false);
      },
    }
  );

  const onSubmit = (credentials: IAccountFormValues) => {
    mutateAccount.mutate(credentials);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: 540 }}>
          <PageHeader
            title="Let's add a LinkedIn account"
            helperProps={{
              link: RouterConstants.DOCS.ACCOUNT.CREATE,
            }}
            hiddenDivider
          />
          <Box py={3}>
            <Box>
              <Typography variant="body2" mb={3}>
                Your LinkedIn credentials are required to run automated actions
                on your behalf.
              </Typography>
              <ElementField
                InputProps={{
                  fullWidth: true,
                  id: "login",
                  label: "Email (LinkedIn)",
                  variant: "outlined",
                  autoComplete: "new-password",
                  disabled: !!accountId,
                }}
                name="login"
                control={control}
                error={errors.login}
              />
              {!accountId && (
                <ElementField
                  component={PasswordField}
                  InputProps={{
                    fullWidth: true,
                    id: "password",
                    label: "Password (LinkedIn)",
                    variant: "outlined",
                    autoComplete: "new-password",
                    disabled: !!accountId,
                  }}
                  name="password"
                  control={control}
                  error={errors.password}
                />
              )}
            </Box>

            <Box pt={5}>
              <Typography
                variant="body2"
                mb={2.75}
                sx={{ display: "flex", alignItems: "center" }}
              >
                Select a country from where you usually login.
                <HelperTooltip
                  title={`${ConfigConstant.APP_NAME} assigns a unique IP address from selected country to ensure a safe connection to your LinkedIn account.`}
                  placement="top"
                ></HelperTooltip>
              </Typography>
              <CountryAutocomplete control={control} errors={errors} />
            </Box>
            {!!isExistingAccount?.count && (
              <Box pt={5}>
                <Typography
                  variant="body2"
                  mb={2.75}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Add account to an existing company or create a new one.
                  <HelperTooltip
                    title="A company helps you with organization of your LinkedIn accounts. Accounts from the same company can't simultaneously target the same
            prospect."
                    placement="top"
                  ></HelperTooltip>
                </Typography>

                <CompanyAutocomplete control={control} error={errors.company} />
              </Box>
            )}
          </Box>

          <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button
              startIcon={<LinkedInIcon />}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading..." : "Continue"}
            </Button>
          </Box>
          <Box
            sx={{
              pt: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <VerifiedUserIcon
              sx={{ color: theme.app.palette.shield }}
              fontSize="small"
            />
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Your credentials are encrypted.
            </Typography>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default CreateAccount;
