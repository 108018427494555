import SettingsItem from "core/components/SettingsItem";
import ConfigConstant from "core/constants/ConfigConstant";
import { createComponents } from "core/utils/componentsHandler";
import WorkHoursField from "modules/Account/components/WorkHoursField";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  Days,
  IAccount,
  IAccountCurrent,
  IAccountDetailItemProps,
  IWorkTime,
} from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import React from "react";
import { useQueryClient } from "react-query";

interface IFormInput {
  work_times: IWorkTime;
  work_times_general: {
    enabled: boolean;
    start_time: string;
    end_time: string;
  };
}

interface IExtendedWorkHourSettings extends IAccountDetailItemProps {
  title?: string;
  body?: string;
}

const WorkHourSettings = (
  props: IExtendedWorkHourSettings
): React.ReactElement | null => {
  const {
    accountId,
    title = "Active hours",
    body = `Schedule when your LinkedIn account is active on ${ConfigConstant.APP_NAME} (sends campaigns and syncs messages).`,
  } = props;
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const { account: currentAccount, dispatch } =
    React.useContext(AccountContext);

  const [formState, setFormState] = React.useState<IFormInput | null>(null);

  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  const defaultValues = {
    work_times: account.work_times,
    work_times_general: {
      enabled: false,
      start_time: "08:00",
      end_time: "17:00",
    },
  };

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          resetForm: true,
          resetFormData: formState,
          format: (d: IFormInput) => {
            const work_days = d.work_times;

            // Adjust each day, if selected to apply same time for each active day
            if (d.work_times_general.enabled) {
              Object.keys(d.work_times).forEach((key) => {
                const day = d.work_times[key as Days];
                if (day.active) {
                  work_days[key as Days] = {
                    active: true,
                    start_time: d.work_times_general.start_time,
                    end_time: d.work_times_general.end_time,
                  };
                }
              });
            }

            const formData = {
              work_times: work_days,
              work_times_general: defaultValues.work_times_general,
            };

            setFormState(formData);

            return {
              login: account.login,
              work_times: work_days,
            };
          },
          onSuccess: (d: IAccountCurrent) => {
            dispatch({
              type: AccountActionType.UPDATE_ACCOUNT,
              payload: {
                ...currentAccount,
              },
            });
            queryClient.invalidateQueries([AccountQuery.is_online, accountId]);
          },
          defaultValues,
        }}
        PaperHeaderProps={{
          title,
          body,
        }}
        components={[
          createComponents(WorkHoursField, "work_times", {
            workTimes: account?.work_times,
          }),
        ]}
      />
    </>
  );
};

export default WorkHourSettings;
