import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import RouterConstants from "core/routes/constants";
import { createComponents } from "core/utils/componentsHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import first from "lodash/first";
import { AccountContext } from "modules/Account/context";
import {
  AccountLogStrategyInputName,
  AccountQuery,
  IAccountLog,
  IAccountLogStatuses,
  IAccountLogStrategy,
} from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getLogContent } from "modules/Account/utils";
import PaymentService from "modules/Payment/services";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

const getSubmitValue = (currentLogContent: IAccountLogStrategy): string => {
  if (
    currentLogContent.disableInput &&
    typeof currentLogContent.key === "string"
  ) {
    return currentLogContent.key;
  }
  return "Confirm";
};

const SyncAccount = (): React.ReactElement | null => {
  const {
    account: { id: accountId },
  } = useContext(AccountContext);
  const queryClient = useQueryClient();
  const history = useHistory();
  const lastAccountIdRef = useRef<number | null>(null);

  const { data: lastLog, isLoading: isLastLogLoading } = useQuery(
    [AccountQuery.last_log, accountId],
    () => AccountService.fetchLastAccountLog(accountId),
    {
      enabled: !!accountId,
      refetchInterval: 5000,
    }
  );

  const { data: account, isLoading: isAccountLoading } = useQuery(
    [AccountQuery.new, accountId],
    () => AccountService.fetchAccount(accountId),
    {
      enabled: !!accountId,
    }
  );

  const fetchAccountSubscription = async () => {
    try {
      const response = await PaymentService.fetchAccountSubscription(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: accountSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    () => fetchAccountSubscription(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  const recentLog = useMemo(() => first(lastLog?.data?.results), [lastLog]);

  const currentLogContent = useMemo(
    () => getLogContent(recentLog, account?.data?.login),
    [recentLog, account?.data?.login]
  );

  const isLoading = useMemo(
    () => isLastLogLoading || isAccountLoading,
    [isLastLogLoading, isAccountLoading]
  );

  useEffect(() => {
    if (accountId !== lastAccountIdRef.current) {
      lastAccountIdRef.current = accountId;
      queryClient.invalidateQueries([AccountQuery.last_log, accountId]);
      queryClient.invalidateQueries([AccountQuery.new, accountId]);
    }
  }, [accountId, queryClient]);

  useEffect(() => {
    if (recentLog?.status === IAccountLogStatuses.ls && account?.data?.person) {
      queryClient.invalidateQueries(AccountQuery.all);
      history.push(RouterConstants.ROOT);
      snackbarHandler.success("Account connected successfully.");
    }
  }, [recentLog, account, queryClient, history]);

  const showLoader = useMemo(
    () =>
      recentLog?.status === IAccountLogStatuses.il ||
      (recentLog?.status === IAccountLogStatuses.ar && recentLog?.answered),
    [recentLog]
  );

  const showForm = useMemo(
    () => recentLog?.status === IAccountLogStatuses.ar && !recentLog?.answered,
    [recentLog]
  );

  const isSubscriptionActive = useMemo(
    () => accountSubscription?.is_subscribed,
    [accountSubscription]
  );

  const isInitialSync = useMemo(
    () =>
      recentLog?.status === IAccountLogStatuses.il && !account?.data?.person,
    [recentLog, account?.data?.person]
  );

  if (isLoading || !recentLog) {
    return null;
  }

  if (recentLog?.status === IAccountLogStatuses.lf) {
    return (
      <Alert severity="error" sx={{ marginBottom: 6 }}>
        <AlertTitle>Failed to connect your account</AlertTitle>
        <Typography>{recentLog?.msg}</Typography>
      </Alert>
    );
  }

  if (!showLoader && !showForm) {
    return null;
  }

  if (!isSubscriptionActive) {
    return null;
  }

  return (
    <Alert severity="error" sx={{ marginBottom: 6 }}>
      {showLoader && (
        <>
          <AlertTitle>
            {isInitialSync
              ? "Connecting your LinkedIn account..."
              : "Setting things up for you..."}
          </AlertTitle>
          {isInitialSync
            ? `LinkedIn requires verification for new connections to your account. You can start setting up your first campaign while you wait for it to show here.`
            : "Please wait while we connect your LinkedIn account. It usually takes only a few minutes."}
        </>
      )}
      {showForm && currentLogContent && (
        <>
          <AlertTitle>{currentLogContent.label}</AlertTitle>
          {currentLogContent.description}
          <Box sx={{ maxWidth: 400 }}>
            <SettingsItem
              FormProps={{
                id: recentLog.id,
                name: [AccountQuery.last_log, accountId],
                func: AccountService.updateAccountLog,
                format: (d: IAccountLog) => d,
                defaultValues: {
                  user_input: {
                    [AccountLogStrategyInputName[currentLogContent.strategy]]:
                      recentLog.user_input || "",
                  },
                },
              }}
              components={
                !currentLogContent.disableInput
                  ? Array.isArray(currentLogContent.key)
                    ? currentLogContent.key.map((item) =>
                        createComponents(
                          ElementField,
                          `user_input.${item.id}`,
                          {
                            component: currentLogContent.input,
                            InputProps: {
                              autoFocus: true,
                              label: item.label,
                              fullWidth: true,
                              sx: { backgroundColor: "#FFF" },
                            },
                          }
                        )
                      )
                    : [
                        createComponents(
                          ElementField,
                          `user_input.${
                            AccountLogStrategyInputName[
                              currentLogContent.strategy
                            ]
                          }`,
                          {
                            component: currentLogContent.input,
                            InputProps: {
                              autoFocus: true,
                              label: currentLogContent.key,
                              fullWidth: true,
                              sx: { backgroundColor: "#FFF" },
                            },
                          }
                        ),
                      ]
                  : []
              }
              SubmitButtonProps={{
                color: "primary",
                value: getSubmitValue(currentLogContent),
                removeDisabled: currentLogContent.disableInput,
                ButtonProps: {
                  size: "medium",
                  sx: { paddingLeft: 6, paddingRight: 6 },
                },
              }}
              hiddenDivider
            />
          </Box>
        </>
      )}
    </Alert>
  );
};

export default SyncAccount;
