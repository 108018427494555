import Close from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { get, last } from "lodash";
import { AccountContext } from "modules/Account/context";
import useUpdateAccount from "modules/Account/hooks/useUpdateAccount";
import useUpdateOnboarding from "modules/Account/hooks/useUpdateOnboarding";
import { IAccountOnboardingIds } from "modules/Account/models";
import useCreateCampaign from "modules/Campaign/hooks/useCreateCampaign";
import { CreateCampaignSteps } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { UserContext } from "modules/User/context";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import GetStartedBox from "../GetStartedBox";

const TOTAL = Object.keys(IAccountOnboardingIds).length;

const OnboardingBanner = (): React.ReactElement | null => {
  const theme = useTheme();
  const history = useHistory();
  const { mutate: createCampaign } = useCreateCampaign();
  const { user } = React.useContext(UserContext);
  const { account } = React.useContext(AccountContext);

  const { mutate: updateOnboarding } = useUpdateOnboarding();
  const { mutate: updateAccount } = useUpdateAccount();

  const { id: accountId, meta_data } = account;
  const onboarding = meta_data?.onboarding;
  const items = useMemo(
    () => [
      !!onboarding?.[IAccountOnboardingIds.campaign_created],
      !!onboarding?.[IAccountOnboardingIds.campaign_sequence],
      !!onboarding?.[IAccountOnboardingIds.campaign_search],
      !!onboarding?.[IAccountOnboardingIds.campaign_launched],
    ],
    [onboarding]
  );

  const INDEX = useMemo(() => items.findIndex((v) => !v), [items]);
  const DONE = useMemo(() => last(items), [items]);

  const PROGRESS = useMemo(
    () => (DONE ? 100 : (100 / TOTAL) * INDEX),
    [DONE, INDEX]
  );

  const handleHideOnboarding = () => {
    if (
      window.confirm(
        "This will hide the Onboarding Checklist. It won't be shown again. Are you sure?"
      )
    ) {
      updateAccount({ meta_data: { ...meta_data, hide_onboarding: true } });
    }
  };

  // 1. check
  const fetchAccountExistingCampaigns = async () => {
    try {
      const { data } = await CampaignService.fetchAccountExistingCampaigns(
        accountId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: data1, isLoading: isLoading1 } = useQuery(
    [IAccountOnboardingIds.campaign_created, accountId],
    () => fetchAccountExistingCampaigns(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !meta_data?.hide_onboarding,
    }
  );

  React.useEffect(() => {
    // Check if value exists and it's not the same as already added
    const newValue = !!data1?.count;
    if (!!data1 && newValue !== onboarding?.campaign_created) {
      updateOnboarding({
        variant: IAccountOnboardingIds.campaign_created,
        value: newValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data1]);

  // // 2. check
  // const fetchExistingCampaignSequences = async () => {
  //   try {
  //     const { data } = await CampaignService.fetchExistingCampaignSequences(
  //       accountId
  //     );
  //     return data;
  //   } catch (err) {
  //     throw new Error(String(err));
  //   }
  // };

  // const { data: data2 } = useQuery(
  //   [IAccountOnboardingIds.campaign_sequence, accountId],
  //   () => fetchExistingCampaignSequences(),
  //   {
  //     keepPreviousData: true,
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //     enabled: !!accountId && !!data1?.count && !onboarding?.campaign_sequence,
  //   }
  // );

  // console.log(data2);

  // React.useEffect(() => {
  //   if (!!data2) {
  //     updateOnboarding({
  //       variant: IAccountOnboardingIds.campaign_sequence,
  //       value: !!data2?.count,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data2]);

  // 4. check
  const fetchAccountLaunchedExistingCampaigns = async () => {
    try {
      const { data } =
        await CampaignService.fetchAccountLaunchedExistingCampaigns(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: data4, isLoading: isLoading2 } = useQuery(
    [IAccountOnboardingIds.campaign_launched, accountId],
    () => fetchAccountLaunchedExistingCampaigns(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        !!accountId &&
        !!data1?.count &&
        // !!data2?.count &&
        !onboarding?.campaign_launched &&
        !meta_data?.hide_onboarding,
    }
  );

  const params = useParams<{ campaignId: string }>();
  const { campaignId } = params;

  const handleNavigate = (tab: CreateCampaignSteps) => {
    // Use campaignId from URL params if available, otherwise use the most recent one
    const currentCampaignId = campaignId || get(data1, "results.0.id");
    const route = RouterConstants.CAMPAIGN.newTab(
      currentCampaignId,
      tab as string
    );

    // If already on the page
    if (RouterUtils.isCurrentRoute(history.location.pathname, route)) {
      snackbarHandler.warning(
        "You are already on this page. Continue right below the onboarding."
      );
      return;
    }

    if (currentCampaignId) {
      // If any campaign to route to exists
      history.replace(route);
      return;
    }

    snackbarHandler.warning("Let's create your campaign first.");
    history.replace(RouterConstants.CAMPAIGN.ALL);
  };

  const handleSkipProspects = () => {
    updateOnboarding({
      variant: IAccountOnboardingIds.campaign_search,
      value: true,
    });

    // Use campaignId from URL params if available, otherwise use the most recent one
    const currentCampaignId = campaignId || get(data1, "results.0.id");
    const route = RouterConstants.CAMPAIGN.newTab(
      currentCampaignId,
      CreateCampaignSteps.review
    );

    if (currentCampaignId) {
      history.push(route);
    } else {
      snackbarHandler.warning("No active campaign. Please create one first.");
      history.replace(RouterConstants.CAMPAIGN.ALL);
    }
  };

  React.useEffect(() => {
    // Check if value exists and it's not the same as already added
    const newValue = !!data4?.count;
    if (!!data4 && newValue !== onboarding?.campaign_launched) {
      updateOnboarding({
        variant: IAccountOnboardingIds.campaign_launched,
        value: newValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data4]);

  // Dismiss if it's finish on first render
  // Only on the first run
  // const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    // if (!firstUpdate.current) {
    //   return;
    // }

    if (DONE && meta_data?.hide_onboarding !== true) {
      updateAccount({ meta_data: { ...meta_data, hide_onboarding: true } });
      // snackbarHandler.success("Congrats! You've finished onboarding");
      // firstUpdate.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DONE, meta_data]);

  // If all steps are finished or onboarding hidden
  if (
    isLoading1 ||
    isLoading2 ||
    INDEX === TOTAL ||
    !!meta_data?.hide_onboarding ||
    // Or it's currently on upgrade page
    RouterUtils.isCurrentRoute(
      RouterConstants.BILLING.PLAN,
      history.location.pathname
    )
  ) {
    return null;
  }

  return (
    <>
      <LinearProgress
        sx={{ borderTopLeftRadius: 2, borderTopRightRadius: 2 }}
        variant="determinate"
        value={PROGRESS}
      />
      <Paper
        sx={{
          backgroundColor: theme.palette.primary.light,
          padding: theme.spacing(4, 7, 2),
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
          marginBottom: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            Hi {user?.first_name || "there"}! Ready to grow on LinkedIn faster?
            Follow these key steps ({DONE ? TOTAL : INDEX + 1}/{TOTAL})
          </Typography>
          <IconButton
            size="small"
            onClick={handleHideOnboarding}
            sx={{
              backgroundColor: "white",
              border: `1px solid ${theme.app.palette.shadow.secondary}`,
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>

        <GetStartedBox
          index={INDEX}
          items={[
            {
              id: IAccountOnboardingIds.campaign_created,
              title: "Create first campaign",
              body: "Campaign helps you to reach your leads with personalized invites and messages.",
              done: !!onboarding?.[IAccountOnboardingIds.campaign_created],
              buttonLabel: "Create campaign",
              skipEnabled: false,
              handler: createCampaign,
            },
            {
              id: IAccountOnboardingIds.campaign_sequence,
              title: "Set up campaign steps",
              body: "Now let's create a sequence of actions to automatically send with delays.",
              done: !!onboarding?.[IAccountOnboardingIds.campaign_sequence],
              buttonLabel: "Add steps",
              skipEnabled: false,
              handler: () => handleNavigate(CreateCampaignSteps.steps),
            },
            {
              id: IAccountOnboardingIds.campaign_search,
              title: "Add leads",
              body: "Import leads to your campaign via LinkedIn URL or add your custom list.",
              done: !!onboarding?.[IAccountOnboardingIds.campaign_search],
              buttonLabel: "Add leads",
              skipEnabled: true,
              skipHandler: handleSkipProspects,
              handler: () => handleNavigate(CreateCampaignSteps.audience),
            },
            {
              id: IAccountOnboardingIds.campaign_launched,
              title: "Launch first campaign",
              body: "Your campaign is ready. Launch it now to start sending.",
              done: !!onboarding?.[IAccountOnboardingIds.campaign_launched],
              buttonLabel: "Go to campaign page",
              skipEnabled: false,
              handler: () => handleNavigate(CreateCampaignSteps.review),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default OnboardingBanner;
