import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TitleDropdownVariant } from "core/models";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import { IAccountOnboardingIds } from "modules/Account/models";
import {
  CampaignDetailTabs,
  CampaignQuery,
  CreateCampaignSteps,
  ICampaign,
  ICampaignSequences,
  IUpdateCampaignFormValues,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import SequenceService from "modules/Sequence/services";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import PageHeader, {
  PageHeaderProps,
  PageHeaderTitleProps,
} from "ui-kit/components/PageHeader";

interface CampaignHeaderProps extends PageHeaderProps {
  campaignId: ICampaign["id"];
  campaignData: ICampaign | undefined;
  disableEditIcon?: PageHeaderTitleProps["disableEditIcon"];
  titleProps?: PageHeaderTitleProps["titleProps"];
}

const CampaignHeader = ({
  campaignId,
  campaignData,
  disableEditIcon,
  titleProps,
  ...rest
}: CampaignHeaderProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const mutateRemoveSequence = useMutation(
    (sequenceId: number) => SequenceService.deleteSequence(sequenceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sequences");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateCampaignRemove = useMutation(
    () => CampaignService.deleteCampaign(campaignId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CampaignQuery.campaign, campaignId]);
        queryClient.invalidateQueries(CampaignQuery.campaigns);

        const dataCampaignSequences: ICampaignSequences | undefined =
          queryClient.getQueryData([CampaignQuery.sequences, campaignId]);

        // Remove all sequences in the campaign
        dataCampaignSequences?.results.forEach(({ sequence }) => {
          mutateRemoveSequence.mutate(sequence?.id);
        });

        // Remove campaign could affect onboarding
        queryClient.invalidateQueries([
          IAccountOnboardingIds.campaign_created,
          accountId,
        ]);

        history.push(RouterConstants.CAMPAIGN.ALL);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleCampaignRemove = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this campaign?")) {
      mutateCampaignRemove.mutate();
    }
  };

  const mutateCampaignName = useMutation(
    (data: IUpdateCampaignFormValues) =>
      CampaignService.updateCampaign(campaignId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CampaignQuery.campaigns);
        queryClient.invalidateQueries([CampaignQuery.campaign, campaignId]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleCampaignNameUpdate = (data: IUpdateCampaignFormValues) => {
    mutateCampaignName.mutate(data);
  };

  const getBacklink = () => {
    if (
      RouterUtils.isPartialRoute(
        history.location.pathname,
        RouterConstants.CAMPAIGN.detailSequenceAll(campaignId)
      )
    ) {
      return {
        text: "Sequences",
        link: RouterConstants.CAMPAIGN.detail(
          campaignId,
          CampaignDetailTabs.sequences
        ),
      };
    }

    if (
      RouterUtils.isPartialRoute(
        history.location.pathname,
        RouterConstants.CAMPAIGN.newSequenceAll(campaignId)
      )
    ) {
      return {
        text: "Sequences",
        link: RouterConstants.CAMPAIGN.newTab(
          campaignId,
          CreateCampaignSteps.steps
        ),
      };
    }

    return {
      text: "Campaigns",
      link: RouterConstants.CAMPAIGN.ALL,
    };
  };

  return (
    <PageHeader
      {...rest}
      backlinkProps={getBacklink()}
      title={campaignData?.name}
      titleProps={titleProps}
      menuProps={[
        {
          id: TitleDropdownVariant.rename,
          label: "Rename",
          icon: EditIcon,
          func: handleCampaignNameUpdate,
        },
        {
          id: TitleDropdownVariant.delete,
          label: "Delete",
          icon: DeleteIcon,
          func: handleCampaignRemove,
        },
      ]}
      // disableSelectedHeader
      // helperProps={{
      //   link: RouterConstants.DOCS.CAMPAIGNS.CREATE,
      // }}
      disableEditIcon={disableEditIcon}
    />
  );
};

export default CampaignHeader;
